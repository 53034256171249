import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';

import { wrapper1200 } from '../utils/style';

import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEOPage';
import Breadcrumbs from '../components/shared/Breadcrumbs';

const Wrapper = styled('div')`
  ${wrapper1200}
  margin: 30px auto;
`;

const List = styled('ul')`
  list-style: none;
  margin-top: 30px;
  padding: 0;
  li {
    padding: 7px 15px;
    margin-top: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  li:nth-of-type(2n) {
    background-color: transparent;
  }
  a {
    opacity: 0.8;
  }
  a:hover,
  a:active {
    text-decoration: none;
    opacity: 1;
  }
`;

const HtmlSitemap = ({ data }) => {
  const Pages = data.allWordpressPage.edges.map(
    edge =>
      edge.node.acf &&
      edge.node.acf.subdomain === 'id' && (
        <li key={edge.node.id} className="page">
          <Link
            to={
              edge.node.slug === 'home-id'
                ? '/'
                : '/' + edge.node.slug.replace('-id', '') + '/'
            }
            dangerouslySetInnerHTML={{
              __html: edge.node.title.replace(' ID', ''),
            }}
          />
        </li>
      )
  );

  return (
    <Layout>
      <SEO
        title="HTML sitemap"
        description="HTML sitemap for Oppa888: id.oppa888.com"
        pathname="/sitemap/"
      />
      <Wrapper>
        <Breadcrumbs
          page_title={'Sitemap'}
          breadcrumbs={[{ title: 'Home', slug: '' }]}
        />

        <h1>HTML sitemap</h1>
        <List>{Pages}</List>
      </Wrapper>
    </Layout>
  );
};

export default HtmlSitemap;

export const pageQuery = graphql`
  query all {
    allWordpressPage(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
          path
          wordpress_parent
          acf {
            subdomain
          }
        }
      }
    }
  }
`;
